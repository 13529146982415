import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

import axios from "axios";
import { Unit, Event } from "@/entities";
import { Environment } from "@/environment";

const http = axios.create({
  baseURL: Environment.PresentationStorageUrl
});

const api = axios.create({
  baseURL: Environment.ApiUrl
});

import store from "@/state/store";

export class GetEventActionParams {
  constructor(
    public region: string,
    public year: number,
    public eventId: string
  ) {}
}

export class GetUnitActionParams {
  constructor(
    public region: string,
    public year: number,
    public unitId: string
  ) {}
}

export class GetProjectScoreParams {
  constructor(public projectId: string, public judgeId: string) {}
}

export class ScoreProjectActionParams {
  constructor(
    public projectId: string,
    public judgeId: string,
    public scores: number[],
    public finalized: boolean
  ) {}
}

export class GetJudgeAssignmentParams {
  constructor(public judgeId: string, public unitId: string) {}
}

@Module({ namespaced: true, name: "Application", store: store })
export class Application extends VuexModule {
  private _units: Array<Unit> = [];
  private _events: Array<Event> = [];
  private _judgeId: string | null = null;
  private _fullscreen = false;

  @Mutation
  public clearUnit(): void {
    this._units = [];
  }

  @Mutation
  public setUnit(newUnit: Unit): void {
    this._units = [newUnit];
  }

  @Mutation
  public setEvent(newEvent: Event): void {
    this._events = [newEvent];
  }

  @Mutation
  public setProjectScore(projectScore: ScoreProjectActionParams): void {
    const currentProject = this._units[0].projects[projectScore.projectId];

    if (currentProject.id !== projectScore.projectId) return;

    this._units[0].projects[projectScore.projectId] = {
      ...currentProject,
      scores: projectScore.scores,
      scoreFinalized: projectScore.finalized
    };
  }

  @Mutation
  public clearEvent(): void {
    this._events = [];
  }

  @Mutation
  public setJudgeId(judgeId: string): void {
    this._judgeId = judgeId;
    localStorage.setItem("judgeId", judgeId);
  }

  @Mutation
  public setFullscreen(isFullscreen: boolean): void {
    this._fullscreen = isFullscreen;
  }

  @Action({ rawError: true })
  public async getUnit(params: GetUnitActionParams): Promise<void> {
    if (this.unit && this.unit.id.toUpperCase() === params.unitId.toUpperCase())
      return;
    this.context.commit("clearUnit");

    const data = await http.get(
      `/${params.region}/${params.year}/${params.unitId}/unit.json`
    );

    if (data.status === 200) {
      this.context.commit("setUnit", data.data);
      if (this._judgeId !== null && this.unit !== null) {
        await this.getJudgeScores(
          new GetJudgeAssignmentParams(
            this._judgeId,
            this.unit.id.toUpperCase()
          )
        );
      }
    }
  }

  @Action({ rawError: true })
  public async getJudgeScores(params: GetJudgeAssignmentParams) {
    const data = await api.post(`/GetJudgeScores`, params);

    if (data.status === 200) {
      for (let i = 0; i < data.data.length; ++i) {
        const project = data.data[i];
        const setScoreParams = new ScoreProjectActionParams(
          project.projectId,
          params.judgeId,
          [
            project.score1,
            project.score2,
            project.score3,
            project.score4,
            project.score5
          ],
          project.finalized
        );
        this.context.commit("setProjectScore", setScoreParams);
      }
    }
  }

  @Action({ rawError: true })
  public async getProjectScore(params: GetProjectScoreParams) {
    const data = await api.post(`/GetScore`, {
      judgeId: params.judgeId,
      projectId: params.projectId
    });

    if (data.status === 200) {
      const setScoreParams = new ScoreProjectActionParams(
        params.projectId,
        params.judgeId,
        [
          data.data.score1,
          data.data.score2,
          data.data.score3,
          data.data.score4,
          data.data.score5
        ],
        data.data.finalized
      );

      this.context.commit("setProjectScore", setScoreParams);
    }
  }

  @Action({ rawError: true })
  public async scoreProject(params: ScoreProjectActionParams): Promise<void> {
    const data = await api.post(`/score`, {
      judgeId: params.judgeId,
      projectId: params.projectId,
      score1: params.scores[0],
      score2: params.scores[1],
      score3: params.scores[2],
      score4: params.scores[3],
      score5: params.scores[4],
      finalized: params.finalized
    });

    if (data.status === 200) {
      this.context.commit("setProjectScore", params);
    }
  }

  @Action({ rawError: true })
  public async getEvent(params: GetEventActionParams): Promise<void> {
    this.context.commit("clearEvent");

    const data = await http.get(
      `/${params.region}/${params.year}/${params.eventId}.json`
    );

    this.context.commit("setEvent", data.data);
  }

  @Action({ rawError: true })
  public async getJudgeAssignment(
    params: GetJudgeAssignmentParams
  ): Promise<void> {
    this.context.commit("clearUnit");

    const data = await api.post("/judgeAssignment", {
      judgeId: params.judgeId,
      unitId: params.unitId
    });

    if (data.status === 200) {
      this.context.commit("setJudgeId", params.judgeId);
      await this.getUnit(
        new GetUnitActionParams(
          data.data.region,
          data.data.year,
          data.data.unitId
        )
      );
    }
  }

  public get event(): Event | null {
    return this._events.length > 0 ? this._events[0] : null;
  }

  public get unit(): Unit | null {
    return this._units.length > 0 ? this._units[0] : null;
  }

  public get judgeId(): string | null {
    return this._judgeId ?? localStorage.getItem("judgeId") ?? null;
  }

  public get fullscreen(): boolean {
    return this._fullscreen;
  }
}
