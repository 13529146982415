export class Environment {
  public static get ApplicationName() {
    return process.env.VUE_APP_APP_TITLE;
  }

  public static get PresentationStorageUrl() {
    return process.env.VUE_APP_PRESENTATION_STORAGE_URL;
  }

  public static get ApiUrl() {
    return process.env.VUE_APP_API_URL;
  }
}
