import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/judge/:judgeId/:unitId",
    name: "ViewJudgeAssignment",
    meta: {
      title: "Unit Listing - PJAS"
    },
    props: true,
    component: () => import("../views/ViewJudgeAssignment.vue")
  },
  {
    path: "/units/:region/:year/:eventId",
    name: "ViewEvent",
    meta: {
      title: "Event Listing - PJAS"
    },
    props: true,
    component: () => import("../views/ViewEvent.vue")
  },
  {
    path: "/projects/:region/:year/:unitId",
    name: "ViewUnit",
    meta: {
      title: "Unit Listing - PJAS"
    },
    props: true,
    component: () => import("../views/ViewUnit.vue")
  },
  {
    path: "/projects/:region/:year/:unitId/:presentationId",
    name: "ViewPresentation",
    meta: {
      title: "Presentation - PJAS"
    },
    props: true,
    component: () => import("../views/ViewPresentation.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  next();
});

export default router;
