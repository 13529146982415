





















import { Vue, Component } from "vue-property-decorator";
import { Environment } from "@/environment";
import {Application} from "@/state/Application/application";
import {getModule} from "vuex-module-decorators";

const ApplicationStore: Application = getModule(Application);

@Component
export default class App extends Vue {
  get appTitle(): string {
    return Environment.ApplicationName;
  }
  public openFullscreen(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const elem = this.$refs.view as any;
    ApplicationStore.setFullscreen(true);
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  }

  mounted(): void {
    window.addEventListener("fullscreenchange", this.onFullscreenChange);
  }

  beforeDestroy(): void {
    window.removeEventListener("fullscreenchange", this.onFullscreenChange);
  }

  private onFullscreenChange() {
    ApplicationStore.setFullscreen(document.fullscreenElement !== null);
  }
}
