import Vue from "vue";
import App from "./App.vue";
import Vuex from "vuex";
import vuetify from "@/plugins/vuetify";
import router from "./router";
import "es6-promise/auto";
import axios from "axios";
import store from "@/state/store";
import { Application } from "@/state/Application/application";
Vue.config.productionTip = false;
Vue.use(Vuex);

const httpClient = axios.create({
  baseURL: "/api",
  timeout: 1000
});

Vue.prototype.$http = httpClient;

store.registerModule("Application", Application);

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
